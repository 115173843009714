import { useEffect, useState } from 'react';

import { youtubeVideoRegex } from '../../constants';

function requestMaxResImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      if (image.width === 1280) {
        resolve();
      } else {
        reject();
      }
    };
    image.onerror = reject;
    image.src = url;
  });
}

export default function useMaxResYoutubeThumb(url) {
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [isYoutubeEmbed, videoId] = youtubeVideoRegex.exec(url) || [];

  useEffect(() => {
    const getYoutubeThumbnail = async (id) => {
      if (isYoutubeEmbed) {
        const maxResThumbUrl = `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`;

        try {
          await requestMaxResImage(maxResThumbUrl);
          setThumbnailUrl(maxResThumbUrl);
        } catch (exception) {
          setThumbnailUrl(`https://i.ytimg.com/vi/${id}/hqdefault.jpg`);
        }
      }
    };

    getYoutubeThumbnail(videoId);
  }, [videoId, isYoutubeEmbed]);

  return {
    thumbnailUrl,
    isYoutubeEmbed
  };
}
