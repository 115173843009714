import { useEffect, useState } from 'react';

import { animotoPlayPageRegex } from '../../constants';

export default function useAnimotoEmbed(url) {
  const [embedData, setEmbedData] = useState({});
  const isAnimotoEmbed = animotoPlayPageRegex.test(url);

  const getAnimotoEmbedData = async (playerUrl) => {
    if (isAnimotoEmbed) {
      try {
        const embedCodeResponse = await fetch(`https://animoto.com/oembeds/create?url=${playerUrl}&ct=&cu=&volume=100&embed_type=html5&options=autostart&format=json`);
        const { html, thumbnail_url : thumbnailUrl, width, height } = await embedCodeResponse.json();
        const iframeSrc = html.match(/src="(.*?)"/)[1];
        const isSquare = width === height ? true : null;
        const isVertical = width < height ? true : null;
        setEmbedData({
          isSquare,
          isVertical,
          iframeSrc,
          thumbnailUrl
        });
      } catch (exception) {
        console.log(exception);
      }
    }
  };

  useEffect(() => {
    getAnimotoEmbedData(url);
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ...embedData,
    isAnimotoEmbed
  };
}
