import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

import animotoPlayer from '../../utils/animotoPlayer';
import {
  defaultPosition,
  player,
  playerWrapper,
  squareWrapper,
  verticalWrapper
} from './EmbedVideoPlayer.module.css';
import useAnimotoEmbed from './useAnimotoEmbed';
import useMaxResYoutubeThumb from './useMaxResYoutubeThumb';

const config = {
  youtube : {
    playerVars : {
      rel      : 0,
      autoplay : 1
    }
  }
};

const playerConfigQuery = graphql`
  query {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;

ReactPlayer.addCustomPlayer(animotoPlayer);

const embedPlayers = {};

export default function EmbedVideoPlayer({ url }) {
  const { site } = useStaticQuery(playerConfigQuery);
  const { iframeSrc, thumbnailUrl, isAnimotoEmbed, isSquare, isVertical } = useAnimotoEmbed(url);
  const { thumbnailUrl : youtubeThumbnailUrl, isYoutubeEmbed } = useMaxResYoutubeThumb(url);
  const playerStyles = classNames(playerWrapper, {
    [squareWrapper]   : !!isSquare,
    [verticalWrapper] : !!isVertical,
    [defaultPosition] : !isSquare && !isVertical
  });

  let width;
  if (isSquare) {
    width = '56.25%';
  }
  if (isVertical) {
    width = '40%';
  }
  if (!isSquare && !isVertical) {
    width = '100%';
  }

  let light = true;

  if (isAnimotoEmbed) {
    light = thumbnailUrl;
  }
  if (isYoutubeEmbed && youtubeThumbnailUrl) {
    light = youtubeThumbnailUrl;
  }

  return (
    <div className={`s-player-wrapper ${playerStyles}`}>
      <ReactPlayer
        key={youtubeThumbnailUrl || thumbnailUrl || url}
        ref={(ref) => {
          embedPlayers[url] = ref;
        }}
        className={player}
        config={{
          ...config,
          facebook : {
            appId : site.siteMetadata.facebook.appId
          },
          animoto : {
            iframeSrc
          }
        }}
        controls
        height={isVertical ? '125%' : '100%'}
        light={light}
        onPlay={() => {
          Object.keys(embedPlayers).forEach((embedUrl) => {
            if (embedUrl !== url) {
              embedPlayers[embedUrl].showPreview();
            }
          });
        }}
        url={url}
        width={width}
      />
    </div>
  );
}

EmbedVideoPlayer.propTypes = {
  url : PropTypes.string.isRequired
};
