import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { animotoPlayPageRegex } from '../constants';

export default class AnimotoPlayer extends PureComponent {
  static canPlay(url) {
    return animotoPlayPageRegex.test(url);
  }

  load() {
    this.load = true;
  }

  render() {
    const { style, config } = this.props;
    const { iframeSrc } = config.animoto;
    const styles = {
      width  : '100%',
      height : '100%',
      ...style
    };

    return (
      <iframe
        allow="autoplay"
        allowFullScreen
        frameBorder={0}
        src={iframeSrc}
        style={styles}
        title="Animoto Video"
      />
    );
  }
}

AnimotoPlayer.propTypes = {
  config : PropTypes.shape({
    animoto : PropTypes.shape({
      iframeSrc : PropTypes.string
    })
  }).isRequired,
  style : PropTypes.object // eslint-disable-line react/forbid-prop-types
};

AnimotoPlayer.defaultProps = {
  style : {}
};

// Do Not remove, this prevents React no key warning from showing from React Player https://github.com/CookPete/react-player/blob/master/src/preload.js#L39
AnimotoPlayer.displayName = 'AnimotoPlayer';
